@import "./base";
@import "./mixins";
@import url('https://fonts.googleapis.com/css?family=Raleway|Righteous|Courgette|Blinker|Libre+Baskerville|Montserrat:400,700&display=swap');
$fa-font-path:"../../node_modules/font-awesome/fonts";
@import "../../node_modules/font-awesome/scss/font-awesome";
@import "../../node_modules/magnific-popup/src/css/main";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
h1,
h2,
h3,
h4 {
  font-family: 'Righteous', Arial, Helvetica, sans-serif;
  color: #3813c2;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 25px;
}

iframe {
  max-width: 100%;
}

p {
  font-family: 'Raleway', sans-serif;
}

ul {
  list-style: none;
}

ul.tick li {
  font-family: 'Raleway', sans-serif;
  &:before {
    content: '✓';
    margin-left: -20px;
    margin-right: 8px;
  }
}

body {
  // overflow: hidden;
  overflow-x: hidden;
}

nav.navbar {
  z-index: 9999;
}

.section-content {
  // &:nth-of-type(even){
  padding-top: 50px;
  padding-bottom: 80px;
  h2{
    font-size: 2em;
  }
  // }
}

[v-cloak] {
  display: none !important;
}

.site-navigation {
  // position: absolute;
  // top: 10px;
  // right: 15px;
  z-index: 999;
  width: 100%;
  height: 70px;
  overflow: hidden;
  list-style: none;
  transition: all .35s;
  display: flex;
  @include my_bp(md) {
    // position: fixed;
    top: auto;
    right: auto;
    height: 70px;
    background: transparent;
    padding-right: 50px;
  }
  &.show {
    height: auto;
    ul {
      left: 0;
    }
  }
  &.sticky_header {
    background: #fff;
    position: fixed;
    top: 0;
    .hamburger-menu {
      span {
        background: #2a0845;
      }
    }
  }
  .logo {
    width: 20%;
    text-align: right;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  ul {
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 9999;
    width: 320px;
    max-width: 90%;
    height: 100vh;
    overflow-x: scroll;
    padding: 30px;
    margin: 0;
    background: #fff;
    transition: all 0.35s;
    @include my_bp(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: auto;
      padding: 0;
      overflow: auto;
      background: transparent;
    }
    li {
      display: block;
      padding: 10px 10px;
      font-size: 14px;
      // font-weight: bold;
      letter-spacing: 0.02rem;
      text-transform: uppercase;
      position: relative;
      &.register {
        &:before {
          display: none;
        }
      }
      &.spotlight {
        background: #3813c2;
        a {
          color: white;
        }
      }
      &.ignite_menu{
        a {
          color: #3813c2;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: #3813c2;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.1s, transform 0.2s ease-in-out;
      }
      &:hover:before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
      &:hover {
        ul {
          display: block;
          opacity: 1;
        }
      }
      &.current-menu-item {
        &:before {
          transform-origin: left top;
          transform: scale(1, 1);
          background: #f00;
        }
        a {
          color: #f00;
          border-color: #2f2f2f;
        }
      }
      a {
        display: block;
        color: #3813c2;
        transition: all 0.35s;
        text-decoration: none;
        &:hover {
          border-color: #2f2f2f;
        }
      }
      ul {
        position: fixed;
        display: none;
        width: 250px;
        background: white;
        margin-left: -10px;
        opacity: 0;
        transition: all 1s ease-in-out;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        @include my_bp(sm) {
          margin-left: -70px;
        }
      }
    }
  }
  .hamburger-menu {
    position: fixed;
    right: 20px;
    width: 100%;
    max-width: 24px;
    height: 22px;
    margin-left: auto;
    margin-top: 20px;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #2a0845;
      border-radius: 10px;
      opacity: 1;
      left: 0;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 2px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }
      &:nth-child(4) {
        top: 18px;
      }
    }
    &.open span {
      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
  .button {
    background: #3813c2;
    display: block;
    margin: 10px 30px;
    padding: 15px;
    width: 200px;
    color: white;
    @include my_bp(sm) {
      width: 275px;
    }
    &:hover {
      background: #f20487;
      text-decoration: none;
    }
    i {
      margin-left: 5px;
    }
  }
}

#ksum-footer {
  background: #0b031b;
  .right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    p {
      font-family: 'Blinker', sans-serif;
      text-align: left;
      color: white;
      font-size: 18px;
      // padding-top: 10px;
      font-weight: normal;
      margin: 0;
    }
    img {
      max-width: 250px;
    }
  }
  .left-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-family: 'Blinker', sans-serif;
      text-align: center;
      color: white;
      font-size: 18px;
      padding-top: 10px;
      font-weight: normal;
    }
    .phone {
      font-size: 25px;
      font-weight: bold;
      div {
        text-align: center;
        color: white;
        i {
          color: #027abb;
          margin-right: 10px;
        }
      }
    }
    .social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        padding: 0 10px;
      }
    }
  }
}

#ksum-copy {
  background: #080113;
  padding: 14px;
  p {
    margin: 0;
    color: white;
    font-family: 'Blinker', sans-serif;
    text-align: center;
  }
}

.buttonDownload {
  display: none;
  position: fixed;
  z-index: 9999;
  border-radius: 5px;
  padding: 10px 25px;
  margin: 10px;
  background-color: #4CC713;
  color: white;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
  cursor: pointer;
  &:hover {
    background-color: #333;
    color: white;
  }
  &:before,
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 15px;
    top: 52%;
  }
  &:before {
    width: 15px;
    height: 5px;
    border-style: solid;
    border-width: 0 2px 2px;
  }
  &:after {
    width: 0;
    height: 0;
    margin-left: 3px;
    margin-top: -7px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: transparent;
    border-top-color: inherit;
    animation: downloadArrow 2s linear infinite;
  }
  &:hover:before {
    border-color: #4CC713;
  }
  &:hover:after {
    border-top-color: #4CC713;
  }
}

@keyframes downloadArrow {
  0% {
    margin-top: -7px;
    opacity: 1;
  }
  0.001% {
    margin-top: -15px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    margin-top: 0;
    opacity: 0;
  }
}

.youtube-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  transition: opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);
  z-index: 63;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font-size: 100%;
  font-family: inherit;
  cursor: pointer;
  line-height: inherit;
  outline: none;
}

.video-item {
  display: block;
  position: relative;
  overflow: hidden;
  clip-path: inset(13% 0 13% 0);
  &:hover svg #bg {
    fill: #c4302b;
    fill-opacity: 1;
  }
}

.pre-header {
  background: #3813c2;
  color: white;
  padding: 1rem 1rem;
  text-align: center;
  p {
    margin: 0;
  }
  a {
    color: yellow;
  }
}

.tsbutton {
  cursor: pointer;
  background-color: #d01c68;
  color: #fff;
  border-color: #d01c68;
  border-radius: 2px;
  border-width: 2px;
  text-transform: uppercase;
  min-width: 195px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .8px;
  padding: 10px 16px;
  line-height: 1.3333333;
  color: white !important;
  text-align: center;
  transition: all 500ms ease;
  &:hover {
    color: white;
  }
}

.ksum-logo{
  max-width: 200px;
  margin: 0 auto;
}